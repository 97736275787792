<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">No Seri Faktur Pajak</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Pajak / No Seri Faktur Pajak </span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Generate"
          @click="onShowGenerateDialog"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined ml-2"
          @click="onExport"
        />
      </div>
      <grid-pajak-seri
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :open="totalOpen"
        :grid-options="options"
        @delete="onConfirmDeletion"
        @edit="onShowEditDialog"
        @request="onRequestData"
      />
      <!-- @assign="onShowAssignDialog" -->
    </div>
    <Dialog
      header="Generate"
      v-model:visible="dialogGenerate"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-pajak-seri-generate
        :item="form"
        :loading="isLoadingSave"
        @generate="onGenerateData"
      />
    </Dialog>
    <Dialog
      header="Edit"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <form-pajak-seri-edit
        :item="form"
        :loading="isLoadingSave"
        @edit="onEditData"
      />
    </Dialog>
    <Dialog
      header="Hapus No. Pajak"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >No. Pajak <strong>{{ form.pajak_no }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          class="p-button-text mr-2"
          @click="dialogHapus = false"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteData"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PajakSeriService from '@/services/PajakSeriService'
import GridPajakSeri from '@/components/pajak/GridPajakSeri'
import FormPajakSeriGenerate from '@/components/pajak/FormPajakSeriGenerate'
import FormPajakSeriEdit from '@/components/pajak/FormPajakSeriEdit'
// import FormPajakSeriAssign from '@/components/pajak/FormPajakSeriAssign'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import dayjs from 'dayjs'

export default {
  components: {
    // FormPajakSeriAssign,
    FormPajakSeriGenerate,
    FormPajakSeriEdit,
    GridPajakSeri,
    Hotkey,
  },
  data() {
    return {
      dataService: null,
      dialogGenerate: false,
      dialogHapus: false,
      dialogEdit: false,
      // dialogAssign: false,
      isLoading: false,
      isLoadingExport: false,
      isLoadingSave: false,
      // isLoadingAssign: false,
      items: [],
      invoices: [],
      form: {},
      totalRecords: 0,
      totalOpen: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'pajak_no',
        sortOrder: -1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new PajakSeriService()
  },
  mounted() {
    this.onLoadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onRequestInvoice(e) {
      this.options = e
      this.onLoadGridInvoice()
    },
    onLoadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          const list_status = [
            { value: 'open', label: 'Open' },
            { value: 'assigned', label: 'Assigned' },
            { value: 'rejected', label: 'Rejected' },
          ]
          this.items.forEach((el) => {
            el.status_name = list_status.find(
              (e) => e.value === el.status
            ).label
          })
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Seri Pajak Order', this)
        })
        .finally(() => (this.isLoading = false))
      this.dataService.count('open').then((res) => {
        this.totalOpen = res.data.data.total
      })
    },
    onLoadGridInvoice() {},
    onShowGenerateDialog() {
      this.dialogGenerate = true
    },
    onShowEditDialog(data) {
      this.form = Object.assign({}, data)
      this.form.expired_at = new Date(data.expired_at)
      this.dialogEdit = true
    },
    /* onShowAssignDialog(data) {
      this.form = data
      this.dialogAssign = true
    }, */
    onGenerateData(data) {
      this.isLoadingSave = true
      if (data) {
        if (data.expired_at) {
          data.expired_at = dayjs(data.expired_at).format('YYYY-MM-DD')
        }
        this.dataService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Seri Pajak',
                detail: 'Generate nomor pajak berhasil.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogGenerate = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Seri Pajak', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onEditData(data) {
      this.isLoadingSave = true
      if (data) {
        const id = data.id
        const newData = {
          pajak_no: data.pajak_no,
          expired_at: new Date(data.expired_at),
          status: data.status,
        }
        this.dataService
          .update(id, newData)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Seri Pajak',
                detail: 'Edit nomor pajak berhasil.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogEdit = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Seri Pajak', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteData() {
      this.dataService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Seri Pajak',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.onLoadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Seri Pajak', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.dataService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'no-seri-pajak.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Retur Pembelian', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onShowGenerateDialog()
      }
    },
  },
}
</script>
